import React from 'react';
import ConnectPageTemplate from '../../../components/Connect/ConnectPageTemplate';

const subtitles = {
  A: (
    <>
      Tintash is a mobile and web development company that provides managed remote teams from around
      the globe. We work closely with you to design and build your digital products. Our clients
      include several <span className="connect__heading-description-bold">Startups</span>,{' '}
      <span className="connect__heading-description-bold">Unicorns</span> and{' '}
      <span className="connect__heading-description-bold">Fortune 500s.</span>
    </>
  ),
  B:
    'Leverage our expert flutter developers to elevate your project, delivering the dream outcomes you’ve always wanted.',
};
function index({location, pageContext}) {
  return (
    <ConnectPageTemplate
      variant={pageContext.variant}
      location={location}
      title="Industry Leading Flutter Development Company"
      subtitles={subtitles}
      metaTitle="Industry Leading Flutter Development Company | Tintash"
      metaDescription="Tintash is an industry leading Flutter development company providing dedicated remote teams globally. We work closely with you to design and build your digital products. Our clients include Startups, Unicorns and Fortune 500s."
      pageviewTitle="Connect/Mobile/Flutter.Development"
    />
  );
}

export default index;
